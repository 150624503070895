// @flow
import React, { useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import isNil from 'lodash/isNil'

import { fetchVendor } from 'core/sifts/actions'
import * as analytics from 'core/analytics/actions'
import { getThreadsById } from 'core/threads/selectors'
import {
  getVendorsById,
  fields,
  isLoading as _isLoading,
} from 'core/sifts/selectors'

import DetailModal from '@edison/webmail-ui/components/Sift/DetailModal'
import {
  Flight,
  Hotel,
  RentalCar,
  Train,
  Shipment,
  Purchase,
  Restaurant,
  Event,
  Bill,
  Cruise,
} from '@edison/webmail-ui/components/Sift/modals'
import SiftError from '@edison/webmail-ui/components/ErrorBoundaries/SiftError'
import ModalImage from '@edison/webmail-ui/components/Sift/components/ModalImage'
import DefaultModalImage from '@edison/webmail-ui/components/Sift/components/DefaultModalImage'
import ErrorBoundary from 'common/ErrorBoundary'

import type { Dispatch } from 'types/redux'

type Props = {
  isOpen: boolean,
  toggle: () => void,
  domain: string,
  payload: Object,
  threadId: string,
}

const registry = {
  flight: Flight,
  hotel: Hotel,
  rentalcar: RentalCar,
  train: Train,
  boardingpass: Flight,
  shipment: Shipment,
  purchase: Purchase,
  restaurant: Restaurant,
  event: Event,
  bill: Bill,
  cruise: Cruise,
}

const View = ({ isOpen, toggle, domain, payload, threadId }: Props) => {
  const dispatch: Dispatch = useDispatch()

  const threadsById = useSelector(getThreadsById)
  const thread = threadsById[threadId]

  const isLoading = useSelector(_isLoading())
  const props = fields[domain](payload, { thread })

  const vendorsById = useSelector(getVendorsById())
  const vendor = vendorsById[props.vendorId]

  const Details = registry[domain]

  useEffect(() => {
    dispatch(analytics.siftCard.useReportSiftCardOpen(domain))
  }, [])

  useEffect(() => {
    if (props.vendorId && isNil(vendor)) {
      dispatch(fetchVendor(props.vendorId))
    }
  }, [vendor])

  const image = useMemo(() => {
    if (vendor) {
      const { background, logo, name } = vendor
      return (
        <ModalImage
          backgroundColor={background}
          image={<img src={logo} alt={name} />}
        />
      )
    } else {
      return <DefaultModalImage domain={domain} />
    }
  }, [vendor, domain])

  return (
    <DetailModal open={isOpen} onClose={toggle} isLoading={isLoading}>
      <ErrorBoundary fallback={() => <SiftError />}>
        {!isLoading && <Details {...props} aside={image} />}
      </ErrorBoundary>
    </DetailModal>
  )
}

export default View
