// @flow
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { Usage } from '@edison/webmail-ui/components/Navigation'
import * as analytics from 'core/analytics'
import { checkAndFetchPlans } from 'core/premium/actions'
import { getFeatureFlags } from 'core/premium/selectors'
import {
  getUpgradePlanNameSelector,
  getUsageSelector,
} from 'core/usage/selectors'

import type { Dispatch } from 'types/redux'

const View = () => {
  const history = useHistory()
  const { userId } = useParams()
  const usage = useSelector(getUsageSelector)
  const featureFlags = useSelector(getFeatureFlags)

  const upgradePlanName = useSelector(getUpgradePlanNameSelector)
  const dispatch: Dispatch = useDispatch()

  const onUpgrade = useCallback(() => {
    dispatch(analytics.actions.premium.userUpgradePrompt())
    history.push(`/u/${userId || 0}/pricing`)
  }, [])

  useEffect(() => {
    dispatch(checkAndFetchPlans())
  }, [])

  return (
    <div className="relative">
      <Usage
        usage={usage || 0}
        total={featureFlags.totalStorageLimit}
        promptNode={null}
        onUpgrade={onUpgrade}
        upgradePlanName={upgradePlanName}
      />
    </div>
  )
}

View.displayName = 'UsageView'

export default View
