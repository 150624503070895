// @flow
import { usePaywall } from 'common/Paywall'
import { useModal } from 'common/modals'
import { useCurrentPlan } from 'core/premium/hooks'
import { getFeatureFlags, isAllowImportAccounts } from 'core/premium/selectors'
import {
  useAuthorizationUrl,
  useProviderLogos,
  useProviderNames,
  useRetrofitAccounts,
  useSupportedProviders,
} from 'core/retrofit/hooks'
import { useGoBackOrGoHome } from 'hooks/useGoBack'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  generatePath,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { modalTypes, routePaths } from 'utils/constants'

import Avatar from '@edison/webmail-ui/components/Avatar'
import {
  AccountQuotaMessage,
  FullScreenLayout,
  OAuthList,
  OAuthListItem,
} from '@edison/webmail-ui/components/Retrofit'
import {
  AccountChip,
  AccountHorizontalList,
} from '@edison/webmail-ui/components/Retrofit/AccountList'
import ASPInstructions from './ASPInstructions'
import IMAPForm from './IMAPForm'
import IMAPProviderForm from './IMAPProviderForm'

import { premiumFreePlanId } from '../../utils/constants'

const AddNewAccount = () => {
  const { getUrl } = useAuthorizationUrl()
  const { accounts, canImport } = useRetrofitAccounts()
  const names = useProviderNames()
  const { logos: providerLogos, getLogo } = useProviderLogos()
  const flags = useSelector(getFeatureFlags)
  const allowImportAccounts = useSelector(isAllowImportAccounts)
  const paywalls = usePaywall()
  const removalConfirm = useModal(modalTypes.accountRemovalConfirmation)

  const [loading, setLoading] = useState(null)

  const currentPlan = useCurrentPlan()
  const isPremium = currentPlan.id !== premiumFreePlanId

  const supportedProviders = useSupportedProviders()

  const reachedQuotaLimit = useMemo(() => {
    return !canImport && accounts.length > 0
  }, [canImport, accounts])

  const quotaMessage = useMemo(() => {
    if (reachedQuotaLimit) {
      return (
        <>
          <AccountQuotaMessage
            isPremium={isPremium}
            count={accounts.length}
            maxCount={flags.totalImportAccountsLimit}
          />
          <div className="-ml-4">
            <AccountHorizontalList
              accounts={accounts}
              cellRenderer={({ data: account, style, className }) => (
                <div key={account.ecUUID} style={style} className={className}>
                  <AccountChip
                    avatar={
                      <Avatar
                        src={getLogo(account)}
                        alt={account.emailAddress}
                      />
                    }
                    emailAddress={account.emailAddress}
                    onClick={() => {
                      removalConfirm.showModal({ ecUUID: account.ecUUID })
                    }}
                  />
                </div>
              )}
            />
          </div>
        </>
      )
    } else return null
  }, [reachedQuotaLimit, accounts, removalConfirm, isPremium])

  async function handleOnClickProvider(provider) {
    if (allowImportAccounts) {
      setLoading(provider)
      await getUrl(provider)
      setLoading(null)
    } else {
      paywalls.retrofit.show()
    }
  }

  return (
    <OAuthList numAccounts={accounts.length} message={quotaMessage}>
      {reachedQuotaLimit && !isPremium
        ? null
        : supportedProviders.map(provider => (
            <OAuthListItem
              key={provider}
              name={names[provider]}
              image={providerLogos[provider]}
              isLoading={provider === loading}
              disabled={!canImport || (!!loading && provider !== loading)}
              onClick={() => handleOnClickProvider(provider)}
            />
          ))}
    </OAuthList>
  )
}

export default () => {
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()
  const { userId } = match.params
  const goBack = useGoBackOrGoHome()

  return (
    <FullScreenLayout
      onBack={() => {
        // $FlowFixMe: Fix location invalid state
        if (location.state && location.state.from) {
          history.push(
            generatePath(routePaths.main, { userId, label: 'inbox' })
          )
        } else {
          goBack()
        }
      }}
    >
      <Switch>
        <Route
          path={routePaths.retrofitIMAPInstructions}
          component={ASPInstructions}
        />
        <Route
          path={routePaths.retrofitIMAPProvider}
          component={IMAPProviderForm}
        />
        <Route path={routePaths.retrofitIMAP} component={IMAPForm} />
        <Route path={match.path} component={AddNewAccount} />
      </Switch>
    </FullScreenLayout>
  )
}
