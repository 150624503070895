// @flow
import React, { Suspense, useEffect } from 'react'
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
  generatePath,
} from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import values from 'lodash/values'
import flow from 'lodash/flow'
import orderBy from 'lodash/orderBy'
import { useSelector } from 'react-redux'
import { hot } from 'react-hot-loader'

import { betaFeatures } from '@edison/webmail-core/utils/constants'
import { routePaths, NAVS } from 'utils/constants'
import { isAccountLocked } from 'core/premium/selectors'
import { isAuthenticated } from 'core/auth/selectors'
import Modals from './common/modals'
import Toasts from './common/toasts'
import Snackbars from './common/snackbars'
import App, {
  withAssets,
  withRefreshAuth,
  withDomainProtect,
  withStorageListener,
  withInitialization,
  withBetaFeature,
  withMobileWall,
  withRetrofitSyncProgress,
  withActiveRetrofitAccount,
  withCastleFingerPrint,
  withSuperSession,
  withUserTutorial,
} from './screens/App'
import Logout from './screens/Logout'
import Main from './screens/Main'
import ComposeModal from './screens/ComposeModal'
import ThreadDetailModal from './screens/ThreadDetailModal'
import ContactDetailModal from './screens/ContactDetailModal'
import DeleteAccountSuccess from './screens/DeleteAccountSuccess'
import AuthInit from './screens/Retrofit/AuthInit'
import AuthCallback from './screens/Retrofit/AuthCallback'
import AddNewAccount from './screens/Retrofit/AddNewAccount'
import AccountLoading from './screens/Retrofit/AccountLoading'
import SyncProgress from './screens/Retrofit/SyncProgress'
import AuthError from './screens/Retrofit/AuthError'

import { useOrderId } from 'core/auth/hooks'
import { useRefreshInitialization } from 'core/initialization/hooks'
import { history } from './store'

import type { Props as LoginProps } from './screens/Login'

type Props = {}

// Lazy loaded routes
const Login = React.lazy<LoginProps>(() =>
  import(/* webpackChunkName: "onboard" */ './screens/Login')
)
const LoginModal = React.lazy<Props>(() =>
  import(/* webpackChunkName: "onboard" */ './screens/Login/LoginModal')
)
const DomainLogin = React.lazy<Props>(() =>
  import(/* webpackChunkName: "onboard" */ './screens/DomainLogin')
)
const DomainSignUp = React.lazy<Props>(() =>
  import(/* webpackChunkName: "onboard" */ './screens/DomainSignUp')
)
const ForgotPassword = React.lazy<Props>(() =>
  import(/* webpackChunkName: "onboard" */ './screens/ForgotPassword')
)

const ResetPassword = React.lazy<Props>(() =>
  import(/* webpackChunkName: "onboard" */ './screens/ResetPassword')
)

const SubaccountSignUp = React.lazy<Props>(() =>
  import(/* webpackChunkName: "onboard" */ './screens/SubaccountSignUp')
)
const Settings = React.lazy<Props>(() =>
  import(/* webpackChunkName: "settings" */ './screens/Settings')
)
const Download = React.lazy<Props>(() =>
  import(/* webpackChunkName: "download" */ './screens/Download')
)
const Verify = React.lazy<Props>(() =>
  import(/* webpackChunkName: "verify"*/ './screens/Verify')
)
const AddHostedDomain = React.lazy<Props>(() =>
  import(/* webpackChunkName: "domains" */ './screens/AddHostedDomain')
)
const AddUserDomain = React.lazy<Props>(() =>
  import(/* webpackChunkName: "domains" */ './screens/AddUserDomain')
)
const DomainVerificationPending = React.lazy<Props>(() =>
  import(
    /* webpackChunkName: "domains" */ './screens/DomainVerificationPending'
  )
)
const Pricing = React.lazy<Props>(() =>
  import(/* webpackChunkName: "premium" */ './screens/Pricing')
)
const PrintPreview = React.lazy<Props>(() =>
  import(/* webpackChunkName: "printPreview" */ './screens/PrintPreview')
)
const AttachmentPreview = React.lazy<Props>(() =>
  import(
    /* webpackChunkName: "attachmentPreview" */ './screens/AttachmentPreview'
  )
)

const LargeAttachmentPreview = React.lazy<Props>(() =>
  import(
    /* webpackChunkName: "largeAttachmentPreview" */ './screens/LargeAttachmentPreview'
  )
)

const Export = React.lazy<Props>(() =>
  import(/* webpackChunkName: "export"*/ './screens/Export')
)
const ExportAuth = React.lazy<Props>(() =>
  import(/* webpackChunkName: "export"*/ './screens/Export/ExportAuth')
)

const WelcomeEmailsUnsubscribe = React.lazy<Props>(() =>
  import(
    /* webpackChunkName: "unsubcribe" */ './screens/WelcomeEmailsUnsubscribe'
  )
)

// The HOCs layer is important!!
// The last one would be placed at the outer layer
// Which would be loaded at first
const DEFAULT_RESOURCES = [
  withAssets,
  withInitialization,
  withRefreshAuth,
  withStorageListener,
]

const protectedRoutes = {
  exportData: {
    path: routePaths.exportData,
    resources: [withAssets, withRefreshAuth, withStorageListener],
    component: Export,
    exact: true,
  },
  retrofitAdd: {
    path: routePaths.retrofitAdd,
    component: AddNewAccount,
    beta: betaFeatures.retrofit,
  },
  retrofitProgress: {
    path: routePaths.retrofitProgress,
    component: SyncProgress,
    exact: true,
    beta: betaFeatures.retrofit,
    resources: [
      withRetrofitSyncProgress,
      withAssets,
      withRefreshAuth,
      withStorageListener,
    ],
  },
  retrofitError: {
    path: routePaths.retrofitError,
    component: AuthError,
    exact: true,
    beta: betaFeatures.retrofit,
    resources: [withAssets, withRefreshAuth, withStorageListener],
  },
  retrofitSuccess: {
    path: routePaths.retrofitSuccess,
    component: AccountLoading,
    exact: true,
    beta: betaFeatures.retrofit,
    resources: [withAssets, withRefreshAuth, withStorageListener],
  },
  domainStatus: {
    path: routePaths.domainStatus,
    component: DomainVerificationPending,
    exact: true,
  },
  addHostedDomain: {
    path: routePaths.addHostedDomain,
    component: AddHostedDomain,
    exact: true,
  },
  addUserDomain: {
    path: routePaths.addUserDomain,
    component: AddUserDomain,
  },
  settings: {
    path: routePaths.settings,
    component: Settings,
    exact: true,
  },
  settingsSection: {
    path: routePaths.settingsSection,
    component: Settings,
  },
  pricing: {
    path: routePaths.pricing,
    component: Pricing,
  },
  mainInbox: {
    path: routePaths.main,
    component: Main,
    resources: [
      withActiveRetrofitAccount,
      withUserTutorial,
      ...DEFAULT_RESOURCES,
    ],
  },
  threadPrintPreview: {
    path: routePaths.threadPrintPreview,
    resources: [withAssets, withRefreshAuth, withStorageListener],
    component: PrintPreview,
    exact: true,
    //high cpu usage when print, avoid use mobile wall.
    mobile: true,
    priority: -1,
  },
  messagePrintPreview: {
    path: routePaths.messagePrintPreview,
    resources: [withAssets, withRefreshAuth, withStorageListener],
    component: PrintPreview,
    exact: true,
    mobile: true,
    priority: -1,
  },
  attachmentPreview: {
    path: routePaths.attachmentPreview,
    resources: [withAssets, withRefreshAuth, withStorageListener],
    component: AttachmentPreview,
    exact: true,
    mobile: true,
    priority: -1,
  },
  largeAttachmentPreview: {
    path: routePaths.largeAttachmentPreview,
    resources: [withAssets, withRefreshAuth, withStorageListener],
    component: LargeAttachmentPreview,
    exact: true,
    mobile: true,
    priority: -1,
  },
}

const publicRoutes = {
  login: {
    path: routePaths.login,
    resources: [
      withAssets,
      withRefreshAuth,
      withStorageListener,
      withDomainProtect,
    ],
    component: Login,
  },
  domainSignUp: {
    path: routePaths.domainSignUp,
    resources: [
      withAssets,
      withRefreshAuth,
      withStorageListener,
      withDomainProtect,
      withCastleFingerPrint,
    ],
    component: DomainSignUp,
  },
  subaccountSignUp: {
    path: routePaths.subaccountSignUp,
    resources: [
      withAssets,
      withRefreshAuth,
      withStorageListener,
      withDomainProtect,
    ],
    component: SubaccountSignUp,
    mobile: true,
  },
}

const defaultRoutes = {
  forgotPassword: {
    path: routePaths.forgotPassword,
    resources: [withDomainProtect, withSuperSession],
    component: ForgotPassword,
    mobile: true,
  },
  resetPassword: {
    path: routePaths.resetPassword,
    resources: [withDomainProtect, withSuperSession],
    component: ResetPassword,
    mobile: true,
  },
  logoutAll: {
    path: routePaths.logoutAll,
    component: Logout,
    resources: [],
  },
  logout: {
    path: routePaths.logout,
    component: Logout,
    resources: [],
    priority: -1,
  },
  domainLogin: {
    path: routePaths.domainLogin,
    component: DomainLogin,
    resources: [],
  },
  verify: {
    path: routePaths.verify,
    resources: [withAssets],
    component: Verify,
    mobile: true,
  },
  download: {
    path: routePaths.download,
    resources: [withAssets],
    component: Download,
    mobile: true,
  },
  oauthInit: {
    path: routePaths.oauthInit,
    component: AuthInit,
    exact: true,
    resources: [withDomainProtect],
  },
  oauth: {
    path: routePaths.oauth,
    component: AuthCallback,
    exact: true,
    resources: [withDomainProtect],
  },
  catchAll: {
    exact: true,
    path: '/*',
    component: CatchAll,
    resources: [withRefreshAuth],
    // All the way at the bottom
    priority: Infinity,
  },
  addAccountLogin: {
    exact: true,
    path: routePaths.addAccountLogin,
    component: LoginModal,
    resources: [withDomainProtect],
    priority: -1,
  },
  exportAuth: {
    exact: true,
    path: routePaths.exportAuth,
    component: ExportAuth,
    resources: [withDomainProtect],
  },
  goodbye: {
    path: routePaths.goodbye,
    component: DeleteAccountSuccess,
    resources: [],
  },
  welcomeEmailsUnsubscribe: {
    path: routePaths.welcomeEmailsUnsubscribe,
    resources: [],
    component: WelcomeEmailsUnsubscribe,
    mobile: true,
  },
}

/**
 * Default application layout and root routes.
 */
const Routes = () => {
  return (
    <ConnectedRouter history={history}>
      <Suspense fallback={null}>
        <App>
          <Switch>{renderRoutes()}</Switch>
          <Modals />
          <Toasts />
          <Snackbars />
        </App>
      </Suspense>
    </ConnectedRouter>
  )
}

const renderRoutes = () => {
  // We want to order routes by their priority across all routes
  const routes = orderBy(
    [
      ...renderProvisionedRoutes(protectedRoutes, ProtectedRoute),
      ...renderProvisionedRoutes(publicRoutes, PublicRoute),
      ...renderProvisionedRoutes(defaultRoutes, Route),
    ],
    'priority'
  ).map(({ route }) => route)
  return routes
}

const renderProvisionedRoutes = (routes, RouteComponent = Route) => {
  return values(routes).map(
    ({
      path,
      component,
      resources = DEFAULT_RESOURCES,
      mobile = false,
      priority = 0,
      beta,
      ...rest
    }) => {
      let Provisioned = component
      // Beta feature HOC should be placed at the bottom layer
      if (!!beta) {
        Provisioned = withBetaFeature(beta, Provisioned)
      }

      Provisioned = flow(resources)(Provisioned)

      if (!mobile) {
        Provisioned = withMobileWall(Provisioned)
      }

      return {
        priority,
        route: (
          <RouteComponent
            key={path}
            path={path}
            component={Provisioned}
            {...rest}
          />
        ),
      }
    }
  )
}

function ProtectedRoute({
  redirectLink = publicRoutes.login.path,
  component,
  render,
  children,
  ...props
}: {
  redirectLink?: string,
}) {
  const location = useLocation()
  const userId = useOrderId()
  const isLocked = useSelector(isAccountLocked)
  const isAuth = useSelector(isAuthenticated())
  const { isInitialized } = useRefreshInitialization()

  if (!isAuth && isInitialized) {
    return (
      <Redirect
        to={{
          pathname: redirectLink,
          state: { next: `${location.pathname}${location.search}` },
        }}
      />
    )
  }

  // If the account is locked
  // Redirect to account screen
  if (isLocked && !/\/u\/\d\/settings\/account/.test(location.pathname)) {
    return (
      <Redirect
        to={generatePath(routePaths.settingsSection, {
          userId,
          section: NAVS.account.value,
        })}
      />
    )
  }

  return (
    <Route {...props}>
      {!!component && React.createElement(component)}
      {!!render && render()}
      <ContactDetailModal />
      <ThreadDetailModal />
      <ComposeModal />
      {children}
    </Route>
  )
}

function PublicRoute(props) {
  const isAuth = useSelector(isAuthenticated())
  const { isInitialized } = useRefreshInitialization()
  const history = useHistory()
  const location = useLocation()
  const userId = useOrderId()

  useEffect(() => {
    // Perform the redrection action only once and
    // Only after the refresh initialization
    if (isAuth && isInitialized) {
      // $FlowFixMe: TODO: location state doesn't exist
      if (location.state && location.state.next) {
        // $FlowFixMe: TODO: location state doesn't exist
        history.replace(location.state.next)
      } else {
        history.replace(
          generatePath(routePaths.main, { userId, label: 'inbox' })
        )
      }
    }
  }, [isInitialized])

  return <Route {...props} />
}

export const routes = { ...protectedRoutes, ...publicRoutes, ...defaultRoutes }

function CatchAll() {
  const location = useLocation()
  const userId = useOrderId()

  return (
    <Redirect
      to={{
        ...location,
        pathname: generatePath(routePaths.main, { userId, label: 'inbox' }),
      }}
    />
  )
}

export default hot(module)(Routes)
